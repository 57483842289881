import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "prices-wrapper" }
const _hoisted_2 = { class: "inputs-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_inputs = _resolveComponent("price-inputs")!
  const _component_labeled_input_wrapper = _resolveComponent("labeled-input-wrapper")!
  const _component_button_el = _resolveComponent("button-el")!
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    btnLabel: _ctx.$t('dashboard-start-spring'),
    "modal-wrapper-class": "spring-modal sm"
  }, {
    body: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header" }, [
        _createElementVNode("h5", { class: "medium" }, "Su krizini çözmede"),
        _createElementVNode("p", { class: "bold mb-0 gray span-text" }, "Ayda ₺5.")
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_price_inputs, {
          onSelectedVal: $setup.catchAmount,
          includeBtn: false
        }, null, 8, ["onSelectedVal"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_labeled_input_wrapper, {
          type: "text",
          placeholder: _ctx.$t('form-name'),
          label: _ctx.$t('form-name'),
          icon: "user",
          "onUpdate:inputVal": $setup.catchName
        }, null, 8, ["placeholder", "label", "onUpdate:inputVal"]),
        _createVNode(_component_labeled_input_wrapper, {
          type: "numeric",
          placeholder: "Telefon",
          label: "Telefon Numarası",
          icon: "phone",
          "onUpdate:inputVal": $setup.catchPhone
        }, null, 8, ["onUpdate:inputVal"]),
        _createVNode(_component_button_el, {
          "btn-class": "bt-bg-primary w-100",
          label: _ctx.$t('support-text'),
          onClick: $setup.nextStep,
          isDisabled: !($setup.amount > 0)
        }, null, 8, ["label", "onClick", "isDisabled"])
      ])
    ]),
    _: 1
  }, 8, ["btnLabel"]))
}