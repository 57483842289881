import {createApp} from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import { key, userStore } from "@/store/modules/UserStore";
import {modalStore} from "@/store/modules/modals";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueAgile from "vue-agile";
import {shared} from "@agile-ts/core";
import reactIntegration from "@agile-ts/vue";
import Multiselect from "vue-multiselect/src/Multiselect.vue";
import MultiSelect from "@vueform/multiselect";
import VueApexCharts from "vue3-apexcharts";
import BootstrapVue3 from "bootstrap-vue-3";
import QrcodeVue from "qrcode.vue";
import i18n from "./i18n";
import InlineSvg from "vue-inline-svg";
//! import VueSplide from "@splidejs/vue-splide/src/js";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// @ts-ignore
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import ApiService from "@/services/ApiService";


//https://livelybone.github.io/vue/vue-select/

// shared.integrate(reactIntegration);

const app = createApp(App)
app
    .use(VueTelInput)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(VueSweetalert2)
    //.use(VueSplide)
    .use(BootstrapVue3)
    .use(userStore, key)
    .use(modalStore, key)
    .component("multi-select", Multiselect)
    .component("multi-select2", MultiSelect)
    .component("QrcodeVue", QrcodeVue)
    .component("inline-svg", InlineSvg)
    .mount("#app");

ApiService.init(app);

// ApiService.init(app)
