export enum Endpoints {
    USER_AUTH_M2M = "oauth/token",
    USER_AUTH_LOGIN = "user/auth/login",
    TRANSLATIONS = "translations",
    FOOTER = "footer",
    MENU = "menu",

    USER_AUTH_LOGOUT = "user/auth/logout",
    //******************* Dashboard *******************************************
    USER_DASHBOARD_DONATIONS = "user/dashboard/donations",
    USER_DASHBOARD_NEEDY = "user/dashboard/needy",
    USER_DASHBOARD_WATER_WELLS = "user/dashboard/water-wells",
    USER_DASHBOARD_COMPLETED_PROJECTS = "user/dashboard/completed-projects",
    USER_DASHBOARD_SACRIFICE_SHARES = "user/dashboard/sacrifice-shares",
    //******************* User Springs *******************************************
    USER_SPRINGS = "user/springs",
    USER_SPRINGS_SHOW = "user/springs/{id}",
    USER_SPRINGS_GOAL = "user/springs/{id}/goal",
    USER_SPRINGS_DONATIONS = "user/springs/{id}/donations",
    USER_SPRINGS_STORE  = "user/springs",
    USER_SPRINGS_UPLOAD_THUMB = "user/springs/{id}/upload-thumb",
    USER_SPRINGS_UPDATE = "user/springs/{id}",
    USER_SPRINGS_DELETE = "user/springs/{id}/delete-request",
    USER_SPRINGS_END = "user/springs/{id}/end-request",
    USER_SPRINGS_RE_APPROVAL = "user/springs/{id}/re-approval",
    USER_SPRINGS_TERMINATE = "user/springs/{id}/terminate",
    //******************* Donations *******************************************
    USER_DONATIONS = "user/donations",
    USER_DONATIONS_NEEDY = "user/donations/needy",
    USER_DONATIONS_NAMED_WATER_WELLS = "user/donations/named-water-wells",
    //******************* Profile *********************************************
    USER_PROFILE = "user/profile",
    USER_PROFILE_NOTIFICATION_PERMISSIONS = "user/profile/notification-permissions",
    USER_PROFILE_UPDATE = "user/profile",
    USER_PROFILE_NOTIFICATION_PERMISSIONS_UPDATE = "user/profile/notification-permissions",
    USER_PROFILE_UPLOAD_AVATAR = "user/profile/upload-avatar",
    USER_PROFILE_DELETE_REQUEST = "user/profile/delete-request",
    //******************* Email Verification *********************************************
    EMAIL_VERIFICATION = "email/verification-notification",
    VERIFY = "verify",
    //******************* Base *********************************************
    FAQS = "faqs",
    FAQS_SHOW = "faqs/{id}",
    VİDEO = "video/{slug}",
    SPECIAL_DAYS = "special-days",
    ANNOUNCEMENTS = "announcements",
    ANNOUNCEMENTS_SHOW = "announcements/{id}",
    COUNTRY = "countries",
    BLOG = "blogs",
    BLOG_SHOW = "blogs/{slug}",
    NEWS = "news",
    NEWS_SHOW = "news/{slug}",
    STORIES = "stories",
    STORY_SHOW = "stories/{slug}",
    WATER_WELL_PRICE = "water-well-prices",
    VOLUNTEERS_STORE = "volunteers",
    FIXED_PAGES_SHOW = "fixed-pages/{slug}",
    ABOUTUS_COUNTRIES= 'aboutus/countries',
    POPUPS = "popups",
    BOOKS = "books",
    CONTACT = "contacts",
    SOCIAL_MEDIA = "social-media",
    CONTACT_STORE = "contacts/create",
    BANK_ACCOUNTS = "bank-accounts",
    KVKK = "kvkk",

    STATISTICS = "statistics",
    //******************* Payment *********************************************
    BASKET = "baskets",
    PAYMENTREQUEST = 'payment/request',
    //******************* Project *********************************************
    WATER_WELLS = "water-wells",
    WATER_WELLS_SHOW = "water-wells/{id}",
    WATER_WELLS_LAST = "water-wells/last",
    SACRIFICE = "sacrifices",
    SACRIFICE_SHOW = "sacrifices/{id}",
    DONATIONS = "donations",
    DONATIONS_STORE = "donations",
    //******************* Spring *********************************************
    SPRINGS =  "springs",
    SPRINGS_SHOW = "springs/{id}",
    VOLUNTEERS = "volunteers",
    BASKETS = "baskets",
    BASKET_INDEX = "baskets/{token}",
    BASKET_BULK = "baskets/bulk-destroy"
}